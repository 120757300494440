/* eslint-disable global-require */
import { contactConfig } from "@energylab/shared-components/constants/config/contactConfig";
import { galleryConfig } from "@energylab/shared-components/constants/config/galleryConfig";
import { resetPasswordConfig } from "@energylab/shared-components/constants/config/resetPasswordConfig";
import { whiteListSignUpConfig } from "@energylab/shared-components/constants/config/whiteListSignUpConfig";
import { nationalities } from "@energylab/shared-components/constants/nationalities";
import { ConfigReducerState } from "@energylab/shared-components/redux/reducers/configReducer";
import { getBrowserLocale } from "@energylab/shared-components/utils/browserLocale";
import { getRoutes } from "@energylab/shared-components/routes";
import * as environment from "environment";
import { registerConfig } from "./constants/config/registerConfig";
import { profileConfig } from "./constants/config/profileConfig";
import { footerConfig } from "./constants/config/footerConfig";
import {  ROUTES } from "./routes";
import { navbarConfig } from "./constants/config/navbarConfig";

const locales = environment.languages;
const defaultLocale = environment.defaultLanguage;

export const companyLogo = "https://res.cloudinary.com/energylab/image/upload/v1653998388/H.Essers/H_Essers_Logo_Vertical_Baseline-Lockup_RGB_RED.png";

export const config: ConfigReducerState = {
    mustAcceptPolicies: ["privacy", "disclaimer", "cookyPolicy"],
    profileConfig,
    registerConfig,
    resetPasswordConfig,
    navbarConfig,
    galleryConfig,
    hideSocialLogin: true,
    ambassadorSettingsConfig: {
        enableRelive: false,
        enableComments: true
    },
    socialConfig: {
        disableInstagram: false,
        disableTwitter: false,
        disableFacebook: false,
        disableLinkedIn: false,
        disableOnChallenCard: false,
        hideFacebookOnCommunity: false,
        hideTwitterOnCommunity: false,
        hideInstagramOnCommunity: false,
        hideLinkedInOnCommunity: true
    },
    dashboardPlacesConfig: {
        articles: ["articles_header"],
        benefits: [],
        challenges: ["challenges_header"],
        events: ["events_header"],
        gallery: ["gallery_header"],
        homePage: ["dashboard"],
        leaderBoard: [],
        mySpace: [],
        mySpaceActivities: [],
        profile: [],
        webShop: [],
        contact: []
    },
    ambassadorImage: "",
    auth: {
        facebookRedirect: environment.auth.facebook,
        googleRedirect: environment.auth.google,
        anonymousToken: environment.anonymousToken
    },
    commentsConfig: {
        disabled: false,
        autoShowSubComments: false,
        autoShowInput: false
    },
    eventsConfig: {
        defaultView: "list",
        viewOptions: []
    },
    contentConfig: {
        showAuthor: true,
        useThemes: true,
        previewType: "card",
        enablePageBanner: true,
        pageBannerImage: "https://res.cloudinary.com/energylab/image/upload/v1561558218/generic-v2/bannerimage.jpg",
        pageBannerOverlay: true,
        alignment: "left"
    },
    contactConfig: {
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1561723053/generic-v2/contactbg.jpg",
        subjectOptions: [
            { key: "1", value: "option1" },
            { key: "2", value: "option2" }
        ],
        siteKey: contactConfig.siteKey
    },
    companyLogo,
    companyName: "H.Essers",
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    coinsConfig: {
        enabled: false,
        coinName: "coin"
    },
    likesConfig: {
        disabled: false
    },
    webshopConfig: {
        enabled: false,
        shopName: ""
    },
    notificationsConfig: {
        enabled: false
    },
    providers: [
        "strava",
        "garmin",
        "fitbit",
        "runkeeper",
        "start2run",
        "pacer",
        "connect"
    ],
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    routeConfig: {
        locales,
        defaultLocale,
        routes: {
            private: getRoutes(ROUTES)
        },
        hideNavigationWhenLoggedOut: true
    },
    nationalities,
    selectedLanguage: getBrowserLocale(defaultLocale, locales),
    seo: {
        pageTitle: "Fit2Grow",
        title: {
            en: "H.Essers",
            de: "H.Essers"
        },
        description: {
            nl: "",
            fr: "",
            en: ""
        },
        image: "",
        site: ""
    },
    uploadPreset: "hessers",
    newUsersConfig: {
        enabled: false,
        hasListOfNewUsers: false
    },
    leaderboardConfig: {
        pageSize: 10
    },
    socialShareConfig: {
        enabled: true,
        copyClipboardEnabled: false,
        shareWithEmail: {
            enabled: false
        }
    },
    whiteListSignUpConfig: {
        enabled: false,
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1561723053/generic-v2/contactbg.jpg",
        subjectOptions: [],
        siteKey: whiteListSignUpConfig.siteKey
    },
    footerConfig
};
