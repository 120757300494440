export const departments = [
    {
        value: "BRUSSEL NATO",
        label: "BRUSSEL NATO",
        children: []
    },
    {
        value: "ZELLIK",
        label: "ZELLIK",
        children: []
    },
    {
        value: "VILVOORDE",
        label: "VILVOORDE",
        children: []
    },
    {
        value: "VILVOORDE ACL",
        label: "VILVOORDE ACL",
        children: []
    },
    {
        value: "VILVOORDE KDL",
        label: "VILVOORDE KDL",
        children: []
    },
    {
        value: "KAMPENHOUT",
        label: "KAMPENHOUT",
        children: []
    },
    {
        value: "ANTWERPEN BASF",
        label: "ANTWERPEN BASF",
        children: []
    },
    {
        value: "ANTWERPEN COVESTRO",
        label: "ANTWERPEN COVESTRO",
        children: []
    },
    {
        value: "BOOM",
        label: "BOOM",
        children: []
    },
    {
        value: "BERGEN OP ZOOM",
        label: "BERGEN OP ZOOM",
        children: []
    },
    {
        value: "BERINGEN",
        label: "BERINGEN",
        children: []
    },
    {
        value: "GENK HQ",
        label: "GENK HQ",
        children: []
    },
    {
        value: "GENK IMPERMO",
        label: "GENK IMPERMO",
        children: []
    },
    {
        value: "GENK WINTERSLAG",
        label: "GENK WINTERSLAG",
        children: []
    },
    {
        value: "GENK ZUID",
        label: "GENK ZUID",
        children: []
    },
    {
        value: "LOMMEL 161",
        label: "LOMMEL 161",
        children: []
    },
    {
        value: "LOMMEL 171",
        label: "LOMMEL 171",
        children: []
    },
    {
        value: "HECHTEL-EKSEL",
        label: "HECHTEL-EKSEL",
        children: []
    },
    {
        value: "TESSENDERLO KANAALWEG",
        label: "TESSENDERLO KANAALWEG",
        children: []
    },
    {
        value: "TESSENDERLO CLEANING CENTER",
        label: "TESSENDERLO CLEANING CENTER",
        children: []
    },
    {
        value: "TESSENDERLO TRINSEO",
        label: "TESSENDERLO TRINSEO",
        children: []
    },
    {
        value: "TESSENDERLO TRANSPORTSTRAAT",
        label: "TESSENDERLO TRANSPORTSTRAAT",
        children: []
    },
    {
        value: "LUIK",
        label: "LUIK",
        children: []
    },
    {
        value: "TERNEUZEN",
        label: "TERNEUZEN",
        children: []
    },
    {
        value: "COURCELLES",
        label: "COURCELLES",
        children: []
    },
    {
        value: "FAMILLEUREUX",
        label: "FAMILLEUREUX",
        children: []
    },
    {
        value: "ZEEBRUGGE",
        label: "ZEEBRUGGE",
        children: []
    },
    {
        value: "MOORSLEDE",
        label: "MOORSLEDE",
        children: []
    },
    {
        value: "GENT AMG",
        label: "GENT AMG",
        children: []
    },
    {
        value: "GENT CHRISTEYNS",
        label: "GENT CHRISTEYNS",
        children: []
    },
    {
        value: "GENT EASTMAN",
        label: "GENT EASTMAN",
        children: []
    },
    {
        value: "GENT SHELL",
        label: "GENT SHELL",
        children: []
    },
    {
        value: "FRANKENTHAL",
        label: "FRANKENTHAL",
        children: []
    },
    {
        value: "FRANKFURT",
        label: "FRANKFURT",
        children: []
    },
    {
        value: "LUXEMBURG",
        label: "LUXEMBURG",
        children: []
    },
    {
        value: "SLUBICE",
        label: "SLUBICE",
        children: []
    },
    {
        value: "PILA",
        label: "PILA",
        children: []
    },
    {
        value: "SOFIA",
        label: "SOFIA",
        children: []
    },
    {
        value: "MONSELICE",
        label: "MONSELICE",
        children: []
    },
    {
        value: "SIZIANO",
        label: "SIZIANO",
        children: []
    },
    {
        value: "MOLDAVIE",
        label: "MOLDAVIE",
        children: []
    },
    {
        value: "ROMAN",
        label: "ROMAN",
        children: []
    },
    {
        value: "ORADEA",
        label: "ORADEA",
        children: []
    },
    {
        value: "BUCHAREST",
        label: "BUCHAREST",
        children: []
    },
    {
        value: "DENEMARKEN",
        label: "DENEMARKEN",
        children: []
    },
    {
        value: "VILNIUS",
        label: "VILNIUS",
        children: []
    },
    {
        value: "DUIVEN",
        label: "DUIVEN",
        children: []
    },
    {
        value: "SCHIPHOL",
        label: "SCHIPHOL",
        children: []
    },
    {
        value: "ISTANBUL",
        label: "ISTANBUL",
        children: []
    },
    {
        value: "DRY_PORT",
        label: "DRY_PORT",
        children: []
    },
    {
        value: "MANCHESTER",
        label: "MANCHESTER",
        children: []
    },
    {
        value: "NOVARA",
        label: "NOVARA",
        children: []
    },
    {
        value: "TARRAGONA",
        label: "TARRAGONA",
        children: []
    },
    {
        value: "WILRIJK",
        label: "WILRIJK",
        children: []
    }
];
