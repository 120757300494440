import { lazy } from "react";

const BenefitsContainer = lazy(() => import("@energylab/shared-components/modules/benefits/benefits/benefitsContainer").then(module => ({ default: module.BenefitsContainer })));

export const ROUTES = [
    {
        element: <BenefitsContainer />,
        path: "advice-from-experts"
    }
]
