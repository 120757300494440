/* eslint-disable linebreak-style */
import * as React from "react";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import * as environment from "environment";
import { FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { emailWhitelistedCreator } from "@energylab/shared-components/utils/emailWhitelistedCreator";
import { compareToFirstPasswordCreator } from "@energylab/shared-components/utils/compareToFirstPassword";
import { getPasswordRules } from "@energylab/shared-components/utils/passwordRule";
import { givenName, familyName, sex, department, selectedLanguage, birthDate, nationality, position } from "./profileStructure";

export const newRegisterStructure: FormStructureScheme = {
    type: "steps",
    steps: [
        {
            title: "register.profilePoliciesStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.email",
                    type: "text",
                    property: "email",
                    validationRules: [
                        {
                            type: "email",
                            message: <FormatMessage id="auth.error.invalidEmail" />
                        },
                        {
                            validatorBuilder: emailWhitelistedCreator,
                            message: <FormatMessage id="error.emailNotWhitelisted" />
                        }
                    ],
                    required: true
                },
                {
                    title: "form.password",
                    type: "password",
                    property: "password",
                    required: true,
                    validationRules: getPasswordRules(environment.password)
                },
                {
                    title: "register.passwordInfo",
                    type: "p",
                    property: "passwordInfo",
                    hide: true
                },
                {
                    title: "form.confirmPassword",
                    type: "password",
                    property: "passwordConfirm",
                    validationRules: [
                        {
                            validatorBuilder: compareToFirstPasswordCreator,
                            message: <FormatMessage id="auth.error.notEqualPassword" />
                        }
                    ],
                    required: true
                }
            ]
        },
        {
            title: "register.profileStepTitle",
            status: "current",
            icon: "",
            formElements: [
                givenName,
                familyName,
                sex,
                birthDate,
                nationality,
                selectedLanguage,
                department,
                position
            ]
        },
        {
            title: "register.profileStepExtraTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.avatar",
                    type: "uploadAvatar",
                    property: "avatar"
                },
                {
                    title: "form.showActivities",
                    type: "checkbox",
                    property: "showActivities"
                }
            ]
        }
    ]
};
