import * as React from "react";
import * as environment from "environment";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { FormStructureScheme, FormElement } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { nationalities } from "@energylab/shared-components/constants/nationalities";
import { SEX } from "@energylab/shared-components/utils/sex";
import { departments } from "./departments";
import { functions } from "./functions";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";


export const givenName: FormElement = {
    title: "auth.fields.givenName",
    placeholder: "auth.fields.givenName",
    type: "text",
    property: "givenName",
    required: true
};

export const familyName: FormElement = {
    title: "auth.fields.familyName",
    placeholder: "auth.fields.familyName",
    type: "text",
    property: "familyName",
    required: true
};

export const birthDate: FormElement = {
    title: "auth.fields.birthDate",
    type: "date",
    property: "birthDate",
    required: true,
    disabled: false,
    validationRules: [
        {
            validatorBuilder: checkMinBirthDateCreator(environment.minYearOld)
        }
    ]
};

export const sex: FormElement = {
    title: "auth.fields.sex",
    type: "radio",
    options: [
        { title: <FormatMessage id="auth.fields.male" />, value: SEX.male },
        { title: <FormatMessage id="auth.fields.female" />, value: SEX.female },
        { title: <FormatMessage id="auth.fields.other" />, value: SEX.other }
    ],
    property: "sex",
    required: true
};

export const nationality: FormElement = {
    title: "form.nationality",
    type: "select",
    options: nationalities.map(nat => (
        {
            title: <FormatMessage id={`nationality.${nat.code}`} />,
            value: nat.code
        }
    )),
    property: "nationality",
    required: true
};

export const department: FormElement = {
    title: "auth.fields.department",
    type: "select",
    property: "department",
    placeholder: "auth.fields.department",
    options: departments.map(dep => ({
        title: dep.label,
        value: dep.value
    })),
    required: true
};

export const position: FormElement = {
    title: "auth.fields.functions",
    type: "select",
    property: "funtions",
    placeholder: "auth.fields.funtions",
    options: functions.map(pos => ({
        title: <FormatMessage id={`auth.fields.functions.${pos.label}`} />,
        value: pos.value
    })),
    required: true
};

export const selectedLanguage: FormElement = {
    title: "auth.fields.selectedLanguage",
    type: "select",
    options: (environment.languages || []).map(locale => (
        {
            title: <FormatMessage id={`locale.${locale}`} />,
            value: locale
        }
    )),
    property: "locale",
    required: true
};

export const newProfileStructure: FormStructureScheme = {
    type: "form",
    formElements: [
        {
            type: "row",
            property: "names",
            formElements: [
                givenName,
                familyName
            ]
        },
        birthDate,
        sex,
        nationality,
        department,
        position,
        selectedLanguage
    ]
};
