export const functions = [
    {
        value: "Arbeider",
        label: "arbeider",
        children: []
    },
    {
        value: "Bediende",
        label: "bediende",
        children: []
    },
    {
        value: "Chauffeur",
        label: "chauffeur",
        children: []
    }
];
